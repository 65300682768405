import * as React from 'react'
import Layout from '../components/layout'
import Voucher from '../templates/voucher'
import Discount from '../templates/discount'
import Packages from '../templates/packages'
import Referral from '../templates/referral'
import '../styles/fees.css'
import ContactFooter from '../templates/contact-footer'
import Seo from '../components/seo'

const Fees = () => {
  return (
    <Layout >
      <Seo title='Fees &amp; Offers' description='Contact me to book a Reiki taster session at 15% discount, or an Ultimate Goddess healing session including crystal Reiki, readings, blessings and rituals, help with fertility, IVF, stress, illness, pain' />
      <div>
        <div className='headingStyles'>
          <h1>Treatments</h1>
        </div>
        <div className='fees'>
          <div className='feesColumns'>
            <p className='marginBottom flexColumn borderRight'>
              <img className='feesIcon'
                alt="crystal"
                src={`/diamond.png`}
                width='30%'
              />
              60 mins Crystal Reiki £60*
              <p style={{"font-size": "small"}}>*accessible price £45 available on request</p>
            </p>
            <p className='marginBottom flexColumn borderRight'>
              <img className='feesIcon'
                alt="womb"
                src={`/uterus.png`}
                width='30%'
              />
              60 mins Womb Connection / Fertility Crystal Reiki £65
            </p>
            <p className= 'flexColumn'>
              <img className='feesIcon'
                alt="goddess icon"
                src={`/lotus.png`}
                width='30%'
              />
              90 mins Ultimate Inner Goddess&nbsp;Treatment £85</p>
          </div>
          <div className='feesList'>
            <p><strong>*Crystal Reiki healing plus your choices from:</strong></p>
            <ul>
              <li>oracle card / rune reading</li>
              <li>Earth-yoni blessing</li>
              <li>womb healing</li>
              <li>self-care ritual (Stress Reduction or Awakening the Divine Feminine).</li>
            </ul>
          </div>
        </div>
      </div>
      <div>
        <div className='offersHeadingStyles'>
          <h1>Offers &amp; Packages</h1>
        </div>
        <div className='backgroundYellow'>
          <ul className='offersList'>
            <Voucher />
            <Discount />
            <Referral />
            <Packages />
          </ul>
        </div>
      </div>
      <div className='headingStyles'>
        <h1>&nbsp;</h1>
      </div>
      <ContactFooter />
    </Layout>
  )
}

export default Fees
