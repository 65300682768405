import * as React from 'react'

const Discount = () => {
  return (
    <>
      <h2>* Introductory <span className='avoidWrap'>Session Special *</span></h2>
      <p>
        <strong>15% discount</strong> for first-time clients so that you can try before you book further sessions or a package.
      </p>
    </>
  )
}

export default Discount
