import * as React from 'react'

const Referral = () => {
  return (
    <div>
      <h2>* Referral <span className='avoidWrap'>Programme *</span></h2>
      <p>
        If you’re a current client and you refer a new client to see me (a family member, friend, neighbour or colleague), the new client will receive the special Try a Session rate and you may receive <strong>an extra 30 mins</strong> added to your session for free.
      </p>
    </div>
  )
}

export default Referral
