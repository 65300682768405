import * as React from 'react'

const Packages = () => {
  return (
    <div>
      <h2>* Session Packages *</h2>
      <p>To get the most benefit from Reiki sessions and reach your ongoing stress management and wellness goals, it's very helpful to enjoy more than one session. </p>
      <p className='marginBottom'>This is why I’m pleased to offer session packages at a discount. Plus you are free to share any number of sessions with family members or give them to friends as gifts.</p>
      <h3 className='centred'>5 Session Package - <span className='avoidWrap'>£202 (saving 10%)</span></h3>
      <h3 className='centred'>10 Session Package - <span className='avoidWrap'>£380 (saving 15%)</span></h3>
    </div>
  )
}

export default Packages
