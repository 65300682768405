import * as React from 'react'
import Pdf from '../../static/Reiki-voucher.pdf';

const Voucher = () => {
  return (
    <>
      <h2>* Gift <span className='avoidWrap'>Voucher *</span></h2>
      <p>
        <strong>A wonderful gift idea</strong> for a loved one is to print out the voucher below, fill it in with the type of session that you would like to gift, then either you or your loved one can get in touch to book the session with me (<em>please note - the voucher itself has no cash value</em>).
      </p>
      <a href = {Pdf} target = "_blank">
        <img
          alt="Printable gift voucher"
          src={`/Reiki-voucher.png`} width='50%'
        />
      </a>

    </>
  )
}

export default Voucher
